import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { getHomepageAction } from "../../reduxThings/slices/DreamArchDesignSlices";
import Label from "../../components/Label/Label";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import "./homepage.scss";
import Slider from "react-slick";
import HousePlan from "../../assests/img/HousePlan.jpg";
import CommercialDesign from "../../assests/img/CommercialDesign.jpeg";
import ArchitecturalDesign from "../../assests/img/ArchitecturalDesign.jpeg";
import InteriorDesign from "../../assests/img/InteriorDesign.jpg";
import CivilConstruction from "../../assests/img/CivilConstruction.jpeg";
import StudioApartment from "../../assests/img/StudioApartment.jpeg";
import RetailSpaceDesign from "../../assests/img/RetailSpaceDesign.avif";
import OfficeDesign from "../../assests/img/OfficeDesign.jpeg";
import EcoFriendlyHomes from "../../assests/img/Eco-FriendlyHomes.jpg";
import IndustrialConstruction from "../../assests/img/IndustrialConstruction.jpg";
import SmartHomeAutomation from "../../assests/img/SmartHomeAutomation.jpeg";
import ModularKitchen from "../../assests/img/ModularKitchen.jpg";
import LandscapingDesign from "../../assests/img/LandscapingDesign.webp";
import Penthouse from "../../assests/img/Penthouse.avif";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import IotImg from "../../assests/img/iot.svg";
import WebImg from "../../assests/img/web.svg";
import SeoImg from "../../assests/img/seo.svg";
import MobileImg from "../../assests/img/mobile.svg";
import UXImg from "../../assests/img/ui.svg";
import SMMImg from "../../assests/img/smm.svg";
import socialImg from "../../assests/img/social.svg";
import scooterImg from "../../assests/img/scooter.svg";
import healthImg from "../../assests/img/health.svg";
import storeImg from "../../assests/img/store.svg";
import tradeImg from "../../assests/img/trade.svg";
import shoppingImg from "../../assests/img/shopping.svg";
import ContactUs from "../../components/ContactUs/ContactUs";
import Footer from "../../components/Footer/Footer";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import workshopImg from "../../assests/img/workshop.svg";
import productImg from "../../assests/img/product.svg";
import ClientConsultation from "../../assests/casestudy/ClientConsultation.svg";
import blogImg from "../../assests/img/blog.svg";
import quoteTopImg from "../../assests/img/quote-top.svg";
import quoteBottomImg from "../../assests/img/quote-bottom.svg";

const Homepage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [key, setKey] = useState(1);
  const [carousalStatus] = useState(true);

  useEffect(() => {
    dispatch(getHomepageAction());
  }, []);
  useEffect(() => {
    if (carousalStatus) {
      window.scrollTo(0, 0);
      const interval = setInterval(() => {
        setKey((v) => {
          return v === 8 ? 1 : v + 1;
        });
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [carousalStatus]);

  const appData = useSelector(
    (e) => e.DreamArchDesignSlice.homepageState.HomepageData
  );

  const clickToNavigate = (page) => {
    if (page === "contactUs") {
      navigate("/contactpage");
    }
  };
  const navigateToBlogPage = () => {
    navigate("/blogpage");
  };
  const arrayImg = [
    {
      id: 1,
      im: HousePlan,
      title: "House Plan",
    },
    {
      id: 2,
      im: CommercialDesign,
      title: "Commercial Design",
    },
    {
      id: 3,
      im: ArchitecturalDesign,
      title: "Architectural Design",
    },
    {
      id: 4,
      im: InteriorDesign,
      title: "Interior Design",
    },
    {
      id: 5,
      im: CivilConstruction,
      title: "Civil Construction",
    },
    {
      id: 6,
      im: StudioApartment,
      title: "Studio Apartment",
    },
    {
      id: 7,
      im: Penthouse,
      title: "Penthouse",
    },
    {
      id: 8,
      im: RetailSpaceDesign,
      title: "Retail Space Design",
    },
    {
      id: 9,
      im: OfficeDesign,
      title: "Office Design",
    },
    {
      id: 10,
      im: EcoFriendlyHomes,
      title: "Eco-Friendly Homes",
    },
    {
      id: 11,
      im: IndustrialConstruction,
      title: "Industrial Construction",
    },
    {
      id: 12,
      im: SmartHomeAutomation,
      title: "Smart Home Automation",
    },
    {
      id: 13,
      im: ModularKitchen,
      title: "Modular Kitchen",
    },
    {
      id: 9,
      im: LandscapingDesign,
      title: "Landscaping Design",
    },
  ];
  let settingsSkills = {
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
        },
      },

      {
        breakpoint: 300,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };
  function SamplePrevArow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ background: "red", borderRadius: "50%", left: "-5px" }}
        onClick={onClick}
      />
    );
  }
  function SampleNextArow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ background: "red", borderRadius: "50%", right: "0px" }}
        onClick={onClick}
      />
    );
  }

  let casestudySkills = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,

    arrows: true,
    nextArrow: <SampleNextArow />,
    prevArrow: <SamplePrevArow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },

      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const renderSlides = () =>
    arrayImg.map((curElem) => (
      <div key={curElem.id}>
        <img
          style={{ height: 200, width: 200, borderRadius: 50 }}
          src={curElem.im}
          alt="..."
        />
        <span className="curElemtitleText">
          <h5>{curElem.title}</h5>
        </span>
      </div>
    ));
  const navigateToBlogListing = () => {
    navigate("/bloglisting");
  };
  const navigateToPortfolioListing = () => {
    navigate("/portfoliolisting");
  };

  const navigateToCaseStudy = () => {
    navigate("/casestudy");
  };
  const CasetudyComponent = ({  }) => {
    return (
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={1}
        activeKey={key}
        className="card_Section"
      >
        <Row className="card_Section">
          <Col sm={3} className="bottom_Section">
            <Nav variant="pills" className="tab_Section">
              <Nav.Item>
                {appData?.casestudy?.processData?.map((curElem) => {
                  return (
                    <Nav.Link eventKey={curElem.id} className="button">
                      {curElem.title}
                    </Nav.Link>
                  );
                })}
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9} className="content">
            <Tab.Content>
              {appData?.casestudy?.processData?.map((curElem) => {
                return (
                  <Tab.Pane eventKey={curElem.id} key={curElem.id}>
                    <div className="img_Content">
                      <div className="img_Section" >
                        <img style={{height: 300, width: 300}} src={ClientConsultation} alt="..." />
                      </div>
                    </div>
                    <div className="right_Section">
                      <div className="inner_Card">
                        <Label
                          title={curElem.details.title}
                          className="heading"
                        ></Label>
                        <Label title={curElem.details.desc}></Label>
                        <CustomButton
                          title="View case study"
                          onClick={navigateToCaseStudy}
                        ></CustomButton>
                      </div>
                    </div>
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );
  };
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ background: "red", borderRadius: "50%" }}
        onClick={onClick}
      />
    );
  }
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ background: "red", borderRadius: "50%" }}
        onClick={onClick}
      />
    );
  }
  let testimonialSkills = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },

      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return typeof appData.title === "string" ? (
    <div className="App">
      <Header />
      <div className="home_page">
        <div className="banner_Text">
          <Label title={appData.subTitle} className="bold"></Label>
          <Label title={appData.des}></Label>
          <CustomButton
            title={appData.buttonTitle}
            onClick={() => clickToNavigate("contactUs")}
          ></CustomButton>
          <div className="cursorP">
            <i className="icon-downs"></i>
          </div>
        </div>
        <Slider {...settingsSkills} className="skill_Sectoion">
          {renderSlides()}
        </Slider>
        <div className="offerings_Section">
          <div className="content_Section">
            <Label title={appData.offerings[0].title}></Label>
            <Label
              className="sub-title"
              title={appData.offerings[0].desc}
            ></Label>
          </div>
          <div className="card_Section">
            <div className="card_Items marginT60">
              <div className="card marginB">
                <div className="content">
                  <img src={UXImg} className="sizes" alt="..." />

                  <Label
                    title={appData.offerings[0].exploration[0].title}
                  ></Label>
                  <Label
                    title={appData.offerings[0].exploration[0].desc}
                  ></Label>
                </div>
              </div>
              <div className="card ">
                <div className="content">
                  <img src={SeoImg} className="sizes" alt="..." />
                  <Label
                    title={appData.offerings[0].exploration[4].title}
                    className="blue"
                  ></Label>
                  <Label
                    title={appData.offerings[0].exploration[4].desc}
                  ></Label>
                </div>
              </div>
            </div>
            <div className="card_Items marginT140">
              <div className="card marginB">
                <div className="content">
                  <img src={MobileImg} className="sizes" alt="..." />
                  <Label
                    title={appData.offerings[0].exploration[1].title}
                    className="yellow"
                  ></Label>
                  <Label
                    title={appData.offerings[0].exploration[1].desc}
                  ></Label>
                </div>
              </div>
              <div className="card ">
                <div className="content">
                  <img src={SMMImg} className="sizes" alt="..." />
                  <Label
                    title={appData.offerings[0].exploration[3].title}
                    className="yellow"
                  ></Label>
                  <Label
                    title={appData.offerings[0].exploration[3].desc}
                  ></Label>
                </div>
              </div>
            </div>
            <div className="card_Items marginT100">
              <div className="card marginB">
                <div className="content">
                  <img src={WebImg} className="sizes" alt="..." />
                  <Label
                    title={appData.offerings[0].exploration[2].title}
                    className=""
                  ></Label>
                  <Label
                    title={appData.offerings[0].exploration[2].desc}
                  ></Label>
                </div>
              </div>
              <div className="card ">
                <div className="content">
                  <img src={IotImg} className="sizes" alt="..." />
                  <Label
                    title={appData.offerings[0].exploration[5].title}
                    className="blue"
                  ></Label>
                  <Label
                    title={appData.offerings[0].exploration[5].desc}
                  ></Label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="why_Section">
          <div className="content_Section">
            <Label title={appData?.why?.title}></Label>
            <Label title={appData?.why?.desc}></Label>
          </div>
          <div className="card_Section">
            <div className="left_Section">
              <Label
                title={appData?.why?.data[0]?.heading}
                className="heading"
              ></Label>
              <Label title={appData?.why?.data[0]?.desc}></Label>
              <div className="tab_Section">
                <CustomButton
                  title={appData?.why?.data[0]?.points[0]?.desc}
                  className="button active"
                ></CustomButton>
                <CustomButton
                  title={appData?.why?.data[0]?.points[1]?.desc}
                  className="button"
                ></CustomButton>
                <CustomButton
                  title={appData?.why?.data[0]?.points[2]?.desc}
                  className="button"
                ></CustomButton>
                <CustomButton
                  title={appData?.why?.data[0]?.points[3]?.desc}
                  className="button"
                ></CustomButton>
              </div>
            </div>
            <div className="right_Section">
              <Label
                title={appData?.why?.carousalCards?.title}
                className="heading"
              ></Label>
              {appData?.why?.carousalCards?.points.map((e) => (
                <div key={e.id}>
                  <h5>{e.mainTitle}</h5>
                  <p>{e.desc}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* casestudy section  */}
        <div className="casestudy_Section">
          <div className="content_Section">
            <Label title={appData.casestudy.title}></Label>
            <Label title={appData.casestudy.desc}></Label>
          </div>
          <Slider {...casestudySkills}>
            <CasetudyComponent />
          </Slider>
        </div>
        {/* testimonial section */}
        <div className="testimonials_Section">
          <div className="content_Section">
            <Label title={appData.testimonials.title}></Label>
            <Label title={appData.testimonials.desc}></Label>
          </div>

          <div className="card_SectionTest">
            <Slider {...testimonialSkills} className="testimonials_Sectoion">
              {appData &&
                appData.testimonials.data.map((element, index) => {
                  return (
                    <div className="testimonals" key={index}>
                      <div className="inner_Testimonals">
                        <img src={quoteTopImg} className="paddB" alt="..." />
                        <Label title={element.detailsTestimony}></Label>
                        <img src={quoteBottomImg} className="paddT" alt="..." />
                      </div>
                      <Label title={element.name} className="from_Name"></Label>
                      <Label
                        title={element.designation}
                        className="post"
                      ></Label>
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>
        <ContactUs />
        <Footer />
      </div>
    </div>
  ) : (
    <div>
      <h1>......loading</h1>
    </div>
  );
};

export default Homepage;
