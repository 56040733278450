import { createSlice } from "@reduxjs/toolkit";
export const initialState = {
  catFact: {
    catFactValue: "",
    loading: false,
  },
  homepageState: {
    HomepageData: {},
  },
  footerState: {
    footerData: {},
  },
  ourServiceState: {
    ourServiceStateData: {},
    loading: false,
  },
  iosState: {
    iosStateData: {},
    loading: false,
  },
  contactUsState: {
    contactUsStateData: {},
    loading: false,
  },
  privacyPolicyState: {
    privacyPolicyStateData: {},
    loading: false,
  },
  termConditionState: {
    termConditionStateData: {},
    loading: false,
  },
  packagesState: {
    packagesStateData: {},
    loading: false,
  },
  blogList: {
    blogListValue: {},
    loading: false,
  },
  blogPageState: {
    blogPageStateData: {},
    loading: false,
  },
  verifyCertificateState: {
    verifyCertificateStateData: {},
    loading: false,
  },
  caseStudyState: {
    caseStudyStateData: {},
    loading: false,
  },
  PortfolioState: {
    PortfolioStateData: {},
    loading: false,
  },
  AboutUsState: {
    AboutUsStateData: {},
    loading: false,
  },
  DetailsPageState: {
    DetailsPageStateData: {},
    loading: false,
  },
  LoginPageState: {
    LoginPageStateData: {},
    loading: false,
  },
  CareerPageState: {
    CareerPageStateData: {},
    loading: false,
  },
};
export const DreamArchDesignSlice = createSlice({
  name: "DreamArchDesign",
  initialState: initialState,
  reducers: {
    getCatAction: (state, action) => {
      state.catFact = {
        ...state.catFact,
        loading: true,
      };
    },
    successCatAction: (state, action) => {
      state.catFact = {
        ...state.catFact,
        catFactValue: action.payload.fact,
        loading: false,
      };
    },
    getHomepageAction: (state, action) => {
      console.log("getHomepage action", action);
      console.log("getHomepage state", state);

      state.homepageState = {
        ...state.homepageState,
      };
    },
    successHomepageAction: (state, action) => {
      console.log(state, "state of home");
      console.log(action, "action of home");

      state.homepageState = {
        ...state.homepageState,
        HomepageData: action.payload,
      };
    },
    getFooterDataAction: (state, action) => {
      state.footerState = {
        ...state.footerState,
      };
    },
    successFooterDataAction: (state, action) => {
      state.footerState = {
        ...state.footerState,
        footerData: action.payload,
      };
    },
    getOurServiceDataAction: (state, action) => {
      state.ourServiceState = {
        ...state.ourServiceState,
        loading: true,
      };
    },
    successOurServiceDataAction: (state, action) => {
      state.ourServiceState = {
        ...state.ourServiceState,
        ourServiceStateData: action.payload,
        loading: false,
      };
    },
    getIosDataAction: (state, action) => {
      state.iosState = {
        ...state.iosState,
        loading: true,
      };
    },
    successIosDataAction: (state, action) => {
      state.iosState = {
        ...state.iosState,
        iosStateData: action.payload,
        loading: false,
      };
    },
    getContactUsData: (state, action) => {
      state.contactUsState = {
        ...state.contactUsState,
        loading: true,
      };
    },
    successContactUsData: (state, action) => {
      state.contactUsState = {
        ...state.contactUsState,
        contactUsStateData: action.payload,
        loading: false,
      };
    },
    getPrivacyPolicyData: (state, action) => {
      state.privacyPolicyState = {
        ...state.privacyPolicyState,
        loading: true,
      };
    },
    successPrivacyPolicyData: (state, action) => {
      state.privacyPolicyState = {
        ...state.privacyPolicyState,
        privacyPolicyStateData: action.payload,
        loading: false,
      };
    },
    getTermConditionData: (state, action) => {
      state.termConditionState = {
        ...state.termConditionState,
        loading: true,
      };
    },
    successTermConditionData: (state, action) => {
      state.termConditionState = {
        ...state.termConditionState,
        termConditionStateData: action.payload,
        loading: false,
      };
    },
    getPackagesData: (state, action) => {
      state.packagesState = {
        ...state.packagesState,
        loading: true,
      };
    },
    successPackagesData: (state, action) => {
      state.packagesState = {
        ...state.packagesState,
        packagesStateData: action.payload,
        loading: false,
      };
    },
    getBlogListAction: (state, action) => {
      console.log("bloglist acxtion called", action);
      state.blogList = {
        ...state.blogList,
        loading: true,
      };
    },
    successBlogListAction: (state, action) => {
      state.blogList = {
        ...state.blogList,
        blogListValue: action.payload,
        loading: false,
      };
    },
    getBlogPageAction: (state, action) => {
      console.log("bloglist acxtion called", action);
      state.blogPageState = {
        ...state.blogPageState,
        loading: true,
      };
    },
    successblogPageStateAction: (state, action) => {
      state.blogPageState = {
        ...state.blogPageState,
        blogPageStateData: action.payload,
        loading: false,
      };
    },
    getVerifyCertificateAction: (state, action) => {
      state.verifyCertificateState = {
        ...state.verifyCertificateState,
        loading: true,
      };
    },
    successVerifyCertificate: (state, action) => {
      state.verifyCertificateState = {
        ...state.verifyCertificateState,
        verifyCertificateStateData: action.payload,
        loading: false,
      };
    },
    getCaseStudyAction: (state, action) => {
      state.caseStudyState = {
        ...state.caseStudyState,
        loading: true,
      };
    },
    successCaseStudyAction: (state, action) => {
      state.caseStudyState = {
        ...state.caseStudyState,
        caseStudyStateData: action.payload,
        loading: false,
      };
    },
    getPortfolioAction: (state, action) => {
      state.PortfolioState = {
        ...state.PortfolioState,
        loading: true,
      };
    },
    successPortfolioAction: (state, action) => {
      state.PortfolioState = {
        ...state.PortfolioState,
        PortfolioStateData: action.payload,
        loading: false,
      };
    },
    getAboutUsAction: (state, action) => {
      state.AboutUsState = {
        ...state.AboutUsState,
        loading: true,
      };
    },
    successAboutUsAction: (state, action) => {
      state.AboutUsState = {
        ...state.AboutUsState,
        AboutUsStateData: action.payload,
        loading: false,
      };
    },
    getDetailsPageAction: (state, action) => {
      state.DetailsPageState = {
        ...state.DetailsPageState,
        loading: true,
      };
    },
    successDetailsPageAction: (state, action) => {
      state.DetailsPageState = {
        ...state.DetailsPageState,
        DetailsPageStateData: action.payload,
        loading: false,
      };
    },
    getLoginPageAction: (state, action) => {
      state.LoginPageState = {
        ...state.LoginPageState,
        loading: true,
      };
    },
    successLoginPageAction: (state, action) => {
      state.LoginPageState = {
        ...state.LoginPageState,
        LoginPageStateData: action.payload,
        loading: false,
      };
    },
    getCareerPageAction: (state, action) => {
      state.CareerPageState = {
        ...state.CareerPageState,
        loading: true,
      };
    },
    successCareerPageAction: (state, action) => {
      state.CareerPageState = {
        ...state.CareerPageState,
        CareerPageStateData: action.payload,
        loading: false,
      };
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  getCatAction,
  successCatAction,
  getHomepageAction,
  successHomepageAction,
  getFooterDataAction,
  successFooterDataAction,
  getOurServiceDataAction,
  successOurServiceDataAction,
  getIosDataAction,
  successIosDataAction,
  getContactUsData,
  successContactUsData,
  getPrivacyPolicyData,
  successPrivacyPolicyData,
  getTermConditionData,
  successTermConditionData,
  getPackagesData,
  successPackagesData,
  getBlogListAction,
  successBlogListAction,
  getBlogPageAction,
  successblogPageStateAction,
  getVerifyCertificateAction,
  successVerifyCertificate,
  getCaseStudyAction,
  successCaseStudyAction,
  getPortfolioAction,
  successPortfolioAction,
  getAboutUsAction,
  successAboutUsAction,
  getDetailsPageAction,
  successDetailsPageAction,
  successLoginPageAction,
  getLoginPageAction,
  successCareerPageAction,
  getCareerPageAction
} = DreamArchDesignSlice.actions;
