import { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Label from "../../components/Label/Label";
import Footer from "../../components/Footer/Footer";
import "./Privacy.scss";
import { useDispatch, useSelector } from "react-redux";
import { getPrivacyPolicyData } from "../../reduxThings/slices/DreamArchDesignSlices";

function Privacy() {
  const [carousalStatus, setCarousalStatus] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPrivacyPolicyData());
  }, []);
  const appData = useSelector(
    (e) => e?.DreamArchDesignSlice.privacyPolicyState.privacyPolicyStateData
  );
  const handleClick = (data) => {
    setCarousalStatus(data);
  };
  return typeof appData.title === "string" ? (
    <div className="main_container">
      <div className="privacy_page">
        <Header handleClick={handleClick} />
        <div className="main_section">
          <div className="top_content">
            <div className="top_heading">
              <Label title={appData.title} className="heading"></Label>
              <div className="inner_text">
                <Label
                  title={appData.data[0].textDesc}
                  className="text1"
                ></Label>
                <Label
                 title={appData?.data[1].textDesc} className="text1"
                ></Label>
                <Label
                 title={appData?.data[2].textDesc} className="text1"
                ></Label>
              </div>
            </div>
          </div>
          <div className="middle_content">
            <div className="middle_heading">
              <Label
                className="heading"
                title={appData.subTitle}
              ></Label>
              <Label
                className="text"
                title={appData.subDesc} ></Label>
              <div className="list">
                <ul>
                  <li>
                  {appData.list[0].textDesc}
                  </li>
                  <li>
                  {appData.list[1].textDesc}
                  </li>
                  <li>
                  {appData.list[2].textDesc}
                  </li>
                </ul>
              </div>
              <div className="inner_text">
                <Label
                  title={appData.descriptionList[0].textDesc}
        
                  className="text1"
                ></Label>
                <Label
                  title={appData.descriptionList[1].textDesc}
                  className="text1"
                ></Label>
                <Label
                  title={appData.descriptionList[2].textDesc}
                
                  className="text1"
                ></Label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  ) : (
    <div>...loading</div>
  );
}

export default Privacy;
