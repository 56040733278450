import React, { useState, useRef, scrollToRef, useEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Label from "../../components/Label/Label";
import BannerRight from "../../assests/img/iosimg.svg";
import UIImg from "../../assests/img/uiimg.svg";
import devprocess from "../../assests/img/devprocess.svg";
import devprocess1 from "../../assests/img/deprocess1.svg";
import devprocess2 from "../../assests/img/devprocess2.svg";
import devprocess3 from "../../assests/img/devprocess3.svg";
import devprocess4 from "../../assests/img/devprocess4.svg";
import "./iosdevelopment.scss";
import ContactUs from "../../components/ContactUs/ContactUs";
import team from "../../assests/img/team.svg";
import tech from "../../assests/img/tech.svg";
import view from "../../assests/img/view.svg";
import HookForm from "../../components/HookForm/HookForm";
import TextField from "../../components/TextField/TextField";
import CustomButton from "../../components/CustomButton/CustomButton";
import Message from "../../util/message";
import Constant from "../../util/constant";
import { useDispatch, useSelector } from "react-redux";
import { getIosDataAction } from "../../reduxThings/slices/DreamArchDesignSlices";
const contactForm = {
  email: {
    name: "email",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.EMAILEMPTY,
      },
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ERRORMESSAGE.EMAILINVALID,
      },
    },
  },
  number: {
    name: "number",
    validate: {
      value: true,
      message: Message.ERRORMESSAGE.CONTACTEMPTY,
    },
    pattern: {
      value: Constant.REGEX.NUMBER,
      message: Message.ERRORMESSAGE.MININVALID,
    },
  },
};

const valueArray = [
  {
    id: 1,
    im: view,
  },

  {
    id: 2,
    im: tech,
  },
  {
    id: 3,
    im: team,
  },
];

function IosDevelopment() {
  const [value, setValue] = useState();
  const [num, setNum] = useState(0);
  const [accordion, setActiveAccordion] = useState(-1);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [form, setcontactForm] = React.useState();
  const [carousalStatus, setCarousalStatus] = useState(true);
  const dispatch = useDispatch();
  const appData = useSelector((e) => e?.DreamArchDesignSlice?.iosState?.iosStateData);

  const handleClick = (data) => {
    setCarousalStatus(data);
  };
  function toggleAccordion(index) {
    setActiveAccordion(index);
  }

  const initialStatus = [
    { src: devprocess },
    { src: devprocess1 },
    { src: devprocess2 },
    { src: devprocess3 },
    { src: devprocess4 },
  ];

  React.useEffect(() => {
    if (carousalStatus) {
      window.scrollTo(0, 0);
      const interval = setInterval(() => {
        setNum((v) => {
          return v === 4 ? 0 : v + 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [carousalStatus]);

  useEffect(() => {
    dispatch(getIosDataAction());
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const onFormSubmit = () => {};
  return typeof appData.title === "string" ? (
    <div className="App">
      <Header handleClick={handleClick} />
      <div className="ios_Page">
        <div
          className="banner_Text_Img"
          style={{
            width: windowSize.innerWidth,
            height: windowSize.innerHeight,
          }}
        >
          <div className="text">
            <div className="main_Heading">{appData.title} </div>
            <div className="sub_Heading"> {appData.subTitle}</div>
          </div>
          <div className="appleImg">
            {" "}
            <img src={BannerRight} class="img-fluid im" alt="..." />
          </div>
        </div>
        <div className="about_Section">
          <div className="content_Section">
            <Label title={appData.industries.title}></Label>
            <Label title={appData.industries.desc}></Label>
          </div>
          <div className="card_Section">
            <div className="card_Items">
              <div className="card">
                <div className="content">
                  <i className="icon-taxi"></i>
                  <Label title={appData.industries.cardData[0].idea}></Label>
                </div>
              </div>
              <div className="card green">
                <div className="content">
                  <i className="icon-scooter green"></i>
                  <Label
                    title={appData.industries.cardData[1].idea}
                    className="green"
                  ></Label>
                </div>
              </div>
              <div className="card neon">
                <div className="content">
                  <i className="icon-world neon"></i>
                  <Label
                    title={appData.industries.cardData[2].idea}
                    className="neon"
                  ></Label>
                </div>
              </div>
            </div>
            <div className="card_Items">
              <div className="card yellow">
                <div className="content">
                  <i className="icon-shopping yellow"></i>
                  <Label
                    title={appData.industries.cardData[3].idea}
                    className="yellow"
                  ></Label>
                </div>
              </div>
              <div className="card blue">
                <div className="content">
                  <i className="icon-health blue"></i>
                  <Label
                    title={appData.industries.cardData[4].idea}
                    className="blue"
                  ></Label>
                </div>
              </div>
              <div className="card pink">
                <div className="content">
                  <i className="icon-social pink"></i>
                  <Label
                    title={appData.industries.cardData[5].idea}
                    className="pink"
                  ></Label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="process">
          <div className="content_Section">
            <Label title={appData.Process.title}></Label>
            <Label title={appData.Process.desc}></Label>
          </div>

          <div className="client_onboard">
            <div className="txt">
              <p className="one">{appData.Process.cardData[0].processNumber}</p>
              <p className="onboarding">
                {appData.Process.cardData[0].processName}
              </p>
              <p className="pro">{appData.Process.cardData[0].subDesc}</p>
            </div>
            <div>
              <img src={initialStatus[num].src} class="img-fluid" alt="img" />
            </div>
          </div>
        </div>
        {/* our value */}
        <Intro
          color="red"
          head={appData.ourValue.title}
          para={appData.ourValue.desc}
        />
        <div className="valueSection">
          {appData.ourValue.cardData.map((curElem) => {
            let { id, heading, subDesc } = curElem;
            return (
              <div key={id} className="inner">
                <div className="im">
                  <img src={valueArray[id - 1].im} alt="cxv" />
                </div>
                <div className="head">{heading}</div>
                <div className="brief">{subDesc}</div>
              </div>
            );
          })}
        </div>
        <Intro
          color="red"
          head={appData.ourWork.title}
          para={appData.ourWork.desc}
        />
        <div className="content_Img ui_Section">
          <div className="content width50">
            <Label
              title={appData?.ourWork.mentalHealthApp[0].heading}
              className="main_Title"
            ></Label>
            <Label
              title={appData?.ourWork.mentalHealthApp[0].paragraph}
              className="inner_Title"
            ></Label>
            <div className="skill_Section">
              <div className="left small50">
                <Label
                  title={appData.ourWork.mentalHealthApp[0]?.points[0].point}
                ></Label>
                <Label
                  title={appData.ourWork.mentalHealthApp[0]?.points[1].point}
                ></Label>
                <Label
                  title={appData.ourWork.mentalHealthApp[0]?.points[2].point}
                ></Label>
                <Label
                  title={appData.ourWork.mentalHealthApp[0]?.points[3].point}
                ></Label>
              </div>
              <div className="right small50">
                <Label
                  title={appData.ourWork.mentalHealthApp[0]?.points[4].point}
                ></Label>
                <Label
                  title={appData.ourWork.mentalHealthApp[0]?.points[5].point}
                ></Label>
                <Label
                  title={appData.ourWork.mentalHealthApp[0]?.points[6].point}
                ></Label>
                <Label
                  title={appData.ourWork.mentalHealthApp[0]?.points[7].point}
                ></Label>
              </div>
            </div>
          </div>
          <div className="image width50">
            <div className="img_Div">
              <img src={UIImg} class="img-fluid" alt="..." />
            </div>
          </div>
        </div>
        <div className="iosApp">
          <div className="head">{appData.buildIos.title}</div>
          <div className="subHeading">{appData.buildIos.subTitle}</div>
          <div className="input_Center">
            <HookForm
              defaultForm={{}}
              init={(form) => setcontactForm(form)}
              onSubmit={onFormSubmit}
            >
              {(formMethod) => {
                return (
                  <div className="form1">
                    <div className="phone_Sec">
                      <PhoneInput
                        className="phone"
                        country="US"
                        value={value}
                        onChange={setValue}
                        placeholder={appData.buildIos.contactPlaceHolder}
                      />
                    </div>
                    <div className="or">-or-</div>
                    <div className="email_Div">
                      <TextField
                        formMethod={formMethod}
                        rules={contactForm.email.validate}
                        name={contactForm.email.name}
                        errors={formMethod?.errors}
                        autoFocus={false}
                        type="text"
                        placeholder={appData.buildIos.emailPLaceHolder}
                      />
                    </div>

                    <div className="submit_Btn">
                      <CustomButton
                        className="bt"
                        title={appData.buildIos.btnTitle}
                      >
                        {" "}
                      </CustomButton>
                    </div>
                  </div>
                );
              }}
            </HookForm>
          </div>
        </div>
        <Intro head={appData.faq.title} para={appData.faq.desc} color="red" />

        {/* faq list */}
        <div className="faq_List">
          <div className="accordion__Block">
            <div className="accordion__faq">
              {appData?.faq.qusetionAndAnswer.map((item) => (
                <div key={item.id} onClick={() => toggleAccordion(item.id)}>
                  <div className="accordion__Faq-heading">
                    <div className="accordion__Icon">
                      {accordion === item.id ? (
                        <>
                          <span className="vertical">
                            {" "}
                            <i className="icon-mail iconCalendar" />
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="vertical">
                            {" "}
                            <i className="icon-mail iconCalendar" />
                          </span>
                        </>
                      )}
                    </div>
                    <div className={accordion === item.id ? "active" : ""}>
                      {item.question}
                    </div>
                  </div>

                  <div className="accordion__Answer">
                    <div
                      className={accordion === item.id ? "active" : "inactive"}
                    >
                      {item.answer}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <ContactUs />

        <Footer></Footer>
      </div>
    </div>
  ) : (
    <div>...loading .....</div>
  );
}

export default IosDevelopment;
const Intro = ({ head, para, color }) => {
  return (
    <div className="introClass">
      <p className="head" style={{ color }}>
        {head}
      </p>
      <p className="para">{para}</p>
    </div>
  );
};
