import { useState, useEffect } from "react";
import "./BlogListing.scss";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import CardImg from "../../assests/img/card.svg";
import IpadImg from "../../assests/img/ipad.svg";
import blogImg from "../../assests/img/blog1.png";
import Label from "../../components/Label/Label";

import Footer from "../../components/Footer/Footer";
import { getBlogListAction } from "../../reduxThings/slices/DreamArchDesignSlices";
import { useDispatch, useSelector } from "react-redux";
const BlogListing = () => {
  let naviagte = useNavigate();
  const [carousalStatus, setCarousalStatus] = useState(true);
  const dispatch = useDispatch();
  const handleClick = (data) => {
    setCarousalStatus(data);
  };
  const clickToNavigate = () => {
    naviagte("/blogpage");
  };
  useEffect(() => {
    dispatch(getBlogListAction());
    window.scrollTo(0, 0);
  }, []);
  const appData = useSelector((e) => e?.DreamArchDesignSlice?.blogList?.blogListValue);
  return typeof appData.topic !== "string" ? (
    <div>...loading</div>
  ) : (
    <div className="bloglisting_Page">
      <div className="center-blog-section">
        <Header handleClick={handleClick} />
        <div className="headerImg">
          <img src={blogImg} className="blog_Img" alt="..." />
          <div className="content_Section">
            <Label className="main_Heading" title={appData.topic}></Label>
            <Label
              className="heading"
              title={appData.title}
            ></Label>
            <div className="inner_TxtSection">
              <p className="inner_Txt">
              {appData.desc}
                <div className="read_More">{appData.btnTitle}</div>
              </p>
            </div>
          </div>
        </div>
        <div className="card_Section">
        {appData?.data?.map((element)=>{
          return(
            <div className="card_Main" key={element.id}>
            <div className="img_Section">
              <img
                src={CardImg}
                className="blog_Img displayDesktop"
                alt="..."
              />
              <img src={IpadImg} className="blog_Img displayIpad" alt="..." />
              <div className="date_Section">
                <span className="date">{element.date}</span>
              </div>
            </div>
            <div className="content_Section">
              <Label className="main_Heading" title={element.topic}></Label>
              <Label
                className="heading"
                title={element.title}
              ></Label>
              <div className="inner_TxtSection">
                <p className="inner_Txt">
                {element.desc}
                  <span className="read_More">{element.btnTitle}</span>
                </p>
              </div>
            </div>
          </div>
          )
        })}
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default BlogListing;
