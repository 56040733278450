import { useState, useEffect } from "react";
import "./BlogPage.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import automation from "../../assests/img/automation.png";
import automation1 from "../../assests/img/automation1.png";
// import appData from "../../Data/Blog(Content11).json";
import { useDispatch, useSelector } from "react-redux";
import { getBlogPageAction } from "../../reduxThings/slices/DreamArchDesignSlices";
const BlogPage = () => {
  const [carousalStatus, setCarousalStatus] = useState(true);
  const dispatch = useDispatch();
  const handleClick = (data) => {
    setCarousalStatus(data);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    dispatch(getBlogPageAction());
  }, []);
  const appData = useSelector(
    (e) => e?.DreamArchDesignSlice?.blogPageState?.blogPageStateData
  );

  return typeof (appData.topic === "string") ? (
    <div className="blog_listingPage">
      <div className="center-blog-section">
        <Header handleClick={handleClick} />
        <div className="blog_Inner">
          <div className="page_Head">
            <p className="smallText">{appData?.topic}</p>
            <p className="heading">{appData?.title}</p>
          </div>
          <p className="innerContent">{appData?.desc}</p>
          <img src={automation} className="w-100" alt="..." />
          <div className="center_Section">
            <div className="margin_Section">
              <p className="heading text-center">{appData?.paraHeading}</p>
              <p className="innerText">{appData?.paraOne}</p>
              <div className="ul_Li">
                <p className="heading_Ul">{appData?.questionOne}</p>

                {appData?.answereList?.map((element) => {
                  return (
                    <ul key={element.id}>
                      {" "}
                      <li> {element.pointOne}</li>
                    </ul>
                  );
                })}
              </div>
            </div>
            <img src={automation1} className="w-100" alt="..." />
            <div className="content">
              <p className="heading">{appData?.questionTwo}</p>
              <p className="innerText">{appData?.answerPara}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  ) : (
    <div>...loading</div>
  );
};

export default BlogPage;
