import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCatAction } from "../../reduxThings/slices/DreamArchDesignSlices";
const TestingPage = () => {
  const dispatch = useDispatch();
  const stateData = useSelector((e) => e.DreamArchDesignSlice.catFact);
  console.log(stateData, "StateData");
  return (
    <div>
      <div className="h1">
        {stateData.loading ? (
          <div>....</div>
        ) : (
          <div>{stateData.catFactValue}</div>
        )}
      </div>
      <Button onClick={() => dispatch(getCatAction())}>call cat api</Button>
    </div>
  );
};

export default TestingPage;
